import styled from "styled-components"
import Font42 from "../../../components/Fonts/Font42"

export const Section = styled.section`
  padding: 60px 0;
`
export const StyledTitle = styled(Font42)`
  text-align: center;
`

export const SectionsWrapper = styled.div`
  display: flex;
  gap: 50px;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  margin-top: 60px;
`
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 260px;
  flex-basis: 30%;
`
