import styled from "styled-components"

export const BannerWrapper = styled.div`
  position: relative;
  margin: 0 -24px;
  display: flex;
  align-items: center;
  overflow: hidden;

  .gatsby-image-wrapper-constrained {
    min-height: 400px;
  }
  .right-photo {
    position: absolute;
    top: 0;
    bottom: 0;
    right: -10%;
    max-width: 684px;
    @media only screen and (min-width: 1100px) {
      right: 0;
    }
  }
`

export const LeftSection = styled.div`
  position: absolute;
  width: ${({ $isMobile }) => ($isMobile ? "100%" : "60%")};
  padding: 40px;
  left: 0;
  z-index: 1;
`
export const RightSection = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 30%;
`
