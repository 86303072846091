import React from "react"
import Layout from "hoc/Layout"
import Main from "../../containers/Courses/Main"

const Courses = () => {
  const SITE_NAME = "Kursy"
  const breadcrumbsData = [
    {
      title: "Strona Główna",
      link: "/",
    },
    SITE_NAME,
  ]

  return (
    <Layout
      breadcrumbsData={breadcrumbsData}
      seoTitle={SITE_NAME}
      containerSize={"xl"}
    >
      <Main />
    </Layout>
  )
}

export default Courses
