import { Card, Grid } from "@mui/material"
import React from "react"
import Font18 from "components/Fonts/Font18"
import Font12 from "components/Fonts/Font12"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { smallParagraphOptions } from "constants/richTextOptions"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { StyledAvatar } from "./style"

const OpinionCard = ({ name, surname, avatar, position, opinion, width }) => {
  const avatarImage = getImage(avatar)
  return (
    <Card style={{ maxWidth: 560, width }}>
      <Grid container>
        <Grid item xs={12} lg={5} mb={{ xs: 2, lg: 0 }}>
          <StyledAvatar alt={`${name} ${surname}`} variant="big">
            {avatarImage && (
              <GatsbyImage image={avatarImage} alt={`${name} ${surname}`} />
            )}
          </StyledAvatar>
        </Grid>
        <Grid item xs={12} lg={7}>
          <Font18>
            {name} {surname}
          </Font18>
          <Font12 sx={{ opacity: 0.6 }} mb={3}>
            {position}
          </Font12>
          <Grid mb={3}>
            {typeof opinion === "string" ? (
              <Font12>{opinion}</Font12>
            ) : (
              renderRichText(opinion, smallParagraphOptions)
            )}
          </Grid>
        </Grid>
      </Grid>
    </Card>
  )
}

export default OpinionCard
