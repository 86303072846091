import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Button from "../../../components/Buttons/Button"
import Font42 from "../../../components/Fonts/Font42"
import { BannerWrapper, LeftSection } from "./Banner.styles"
import { useMediaQuery } from "@mui/material"
import { useTheme } from "@mui/system"

const Banner = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("870"))

  return (
    <section>
      <BannerWrapper>
        <StaticImage
          src="../../../images/courses/courses-banner.png"
          alt="Kursy Medira"
          placeholder="blurred"
        />
        <LeftSection $isMobile={isMobile}>
          <Font42>
            Kursy dla Opiekunów Osób Starszych
            <br />
            UCZ SIĘ I ODBIERZ CERTYFIKAT
          </Font42>
          <Button
            to={"/kursy/lista"}
            sx={{
              width: 300,
              height: 70,
              fontSize: 22,
              fontWeight: 700,
              textTransform: "uppercase",
              marginTop: 5,
            }}
          >
            Poznaj nasze kursy
          </Button>
        </LeftSection>
        {!isMobile && (
          <StaticImage
            src="../../../images/courses/courses-right.png"
            alt="Opiekunka z podopieczną"
            placeholder="blurred"
            className="right-photo"
          />
        )}
      </BannerWrapper>
    </section>
  )
}

export default Banner
