import React from "react"
import Banner from "./Banner/Banner"
import ChartSection from "./ChartSection/ChartSection"
import ForWhoAreOurCourses from "./ForWhoAreOurCourses/ForWhoAreOurCourses"
import Reviews from "./Reviews/Reviews"
import WhyToBuy from "./WhyToBuy/WhyToBuy"

const Main = () => {
  return (
    <>
      <Banner />
      <WhyToBuy />
      <ForWhoAreOurCourses />
      <ChartSection />
      <Reviews />
    </>
  )
}

export default Main
