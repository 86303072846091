import React from "react"
import Font24 from "../../../components/Fonts/Font24"
import Font16 from "../../../components/Fonts/Font16"
import {
  Section,
  SectionsWrapper,
  StyledTitle,
  Wrapper,
} from "./WhyToBuy.styled"
// import {
//   WorkspacePremiumIcon,
//   VideocamIcon,
//   UpdateIcon,
// } from "@mui/icons-material"
import UpdateIcon from "@mui/icons-material/Update"
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium"
import VideocamIcon from "@mui/icons-material/Videocam"

const ICON_SIZE = 90

const sections = [
  {
    icon: (
      <WorkspacePremiumIcon
        sx={{ width: ICON_SIZE, height: ICON_SIZE, color: "#3BB2C4" }}
      />
    ),
    title: "CERTYFIKAT UKOŃCZENIA KURSU",
    description:
      "Dzięki zakupowi kursu zdobędziesz certyfikat ukończenia szkolenia, który będziesz mógła wykorzystać do negocjacji stawki na swoim zleceniu, wzmocnienia siły swojego CV i stania się rozchwytywaną na rynku pracy. Ponadto certyfikat jest doskonałą podstwą do negocjacji Twojego wynagrodzenia!",
  },
  {
    icon: (
      <VideocamIcon
        sx={{ width: ICON_SIZE, height: ICON_SIZE, color: "#3BB2C4" }}
      />
    ),
    title: "MATERIAŁY VIDEO",
    description:
      "Nagrane treści w wersji video stanowią przystępną i łatwą w zapamiętaniu treść. Dzięki nim w przyjemny sposób nauczysz się nowych zagadnień. W dowolnym momencie może cofnąć film i wrócić do interesujących Cię tematów. Kurs możesz oglądać wielokrotnie. ",
  },
  {
    icon: (
      <UpdateIcon
        sx={{ width: ICON_SIZE, height: ICON_SIZE, color: "#3BB2C4" }}
      />
    ),
    title: "OPTYMALNY CZAS",
    description:
      "Wiemy, że Twój czas jest na wagę złota, dlatego nasz kurs jest krótki i przejrzysty. Dzięki niemu w niedługim czasie będziesz mogła dowiedzieć się wszystkich najważniejszych rzeczy o opiece nad osobami starszymi.  ",
  },
]

const WhyToBuy = () => {
  return (
    <Section>
      <StyledTitle tt="uppercase">
        Dlaczego warto skorzystać z kursu?
      </StyledTitle>
      <SectionsWrapper>
        {sections.map(({ icon, title, description }) => (
          <Wrapper>
            {icon}
            <Font24
              color="secondary"
              sx={{ margin: "20px 0", textAlign: "center" }}
            >
              {title}
            </Font24>
            <Font16 sx={{ textAlign: "center", lineHeight: "25px" }}>
              {description}
            </Font16>
          </Wrapper>
        ))}
      </SectionsWrapper>
    </Section>
  )
}

export default WhyToBuy
