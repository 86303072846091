import { Grid } from "@mui/material"
import React from "react"
import OpinionCard from "../../Feedback/OpinionCard/OpinionCard"

import { StyledTitle } from "./Reviews.styled"

const opinions = [
  {
    name: "Grażyna",
    surname: "Gawęda",
    position: "Opiekunka osób starszych z 5 letnim stażem",
    opinion:
      "Kupiłam pakiet kursów i kilka minut później otrzymałam kursy i  kilka certyfikatów, a miesiąc później dzięki temu mogłam wynegocjować w agencji większe wynagrodzenie i lepsze warunki zlecenia. Nie żałuje i polecam wszystkim. ",
  },
  {
    name: "Edward",
    surname: "Kostrzycki",
    position: "Opiekun osób starszych z rocznym stażem",
    opinion:
      "Zakup kursu przebiegł pomyślnie, chwile poźniej dostałem materiały szkoleniowe i certyfikaty. Faktycznie to przydatna rzecz, bo wszystkie agencje pytały mnie czy mam jakieś dyplomy, teraz mam i dzięki temu zarabiam znacznie więcej a agencje konkurują o to abym u nich pracował.",
  },
  {
    name: "Teresa",
    surname: "Waligóra",
    position: "Opiekunka osób starszych z 2 letnim stażem",
    opinion:
      "Interesował mnie tylko kurs o osobach leżących bo na zleceniach miałam problem ze zmianą pościeli i ubrania mojemu pacjentowi. Obejrzałam krótki kurs i już wszystko wiem, a dzięki dyplomowi dostałam podwyżkę w mojej agencji.",
  },
  {
    name: "Katarzyna",
    surname: "Sośnica",
    position: "Początkująca opiekunka osób starszych",
    opinion:
      "Chciałam zacząć wyjeżdżać do opieki do Niemiec, ale wszystkie agencje wymagały ode mnie doświadczenia którego nie miałam. Za niewielką kwotę kupiłam kurs, otrzymałam certyfikat a tydzień później znalazłam pracę. Najlepiej zainwestowane pieniądze.",
  },
  {
    name: "Magdalena",
    surname: "Walas",
    position: "Córka osoby chorującej na demencję",
    opinion:
      "Moja mama zaczęła mieć objawy demencji, muszę opiekować się nią sama dlatego chciałam dowiedzieć się czegoś więcej o tej chorobie. Po obejrzeniu kursu o demencji kupiłam pakiet wszystkich szkoleń, bo bardzo mi się podobały, były krótkie i rzeczowe. Dzięki nim jest mi łatwiej rozumieć zachowania mamy.",
  },
  {
    name: "Halina",
    surname: "Tykowicz",
    position: "Opiekuna osób starszych z 10 letnim stażem",
    opinion:
      "W opiece pracuje lata, więc wiedzy z zakresu opieki nie potrzebowałam, ale na ostatnim zleceniu przekonałam się, że wiedzę z pierwszej pomocy należy systematycznie odświeżać! Kupiłam kurs z pierwszej pomocy i czuje się pewniej, że znowu pamiętam jak pomóc w potrzebie, polecam wszystkim!",
  },
]

const Reviews = () => {
  return (
    <section
      style={{
        backgroundColor: "#F7F4FF",
        margin: "0 -24px",
        padding: "60px 24px",
        marginBottom: "-150px",
      }}
    >
      <StyledTitle tt="uppercase">
        POZNAJ OPINIE NASZYCH ABSOLWENTÓW
      </StyledTitle>
      <Grid container justifyContent="center" gap="40px" my="60px" width="97%">
        {opinions.map(opinion => (
          <OpinionCard {...opinion} />
        ))}
      </Grid>
    </section>
  )
}

export default Reviews
