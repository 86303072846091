import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Font18 from "../../../components/Fonts/Font18"
import { Section, StyledTitle } from "./ChartSection.styled"
import Grid from "@mui/material/Grid"

const ChartSection = () => {
  return (
    <Section>
      <StyledTitle tt="uppercase">
        KURSY SPRAWDZONE PRZEZ SETKI OPIEKUNÓW OSÓB STARSZYCH
      </StyledTitle>
      <Font18
        sx={{ textAlign: "center", maxWidth: "80%", margin: "40px auto 0" }}
      >
        Z naszych kursów skorzystały już setki zadowolonych Opiekunów Osób
        Starszych, którzy dzięki otrzymanym certyfikatom podniosły swoje
        zarobki, siłę na rynku pracy i uznanie klientów i pracodawców.
      </Font18>
      <Grid container alignItems="center" justifyContent="center" my={10}>
        <StaticImage
          src="../../../images/courses/courses-chart.png"
          alt="Wykresy zakupionych kursów Mediry"
          placeholder="blurred"
          maxWidth="100px"
        />
      </Grid>
    </Section>
  )
}

export default ChartSection
