import React from "react"
import Font18 from "../../../components/Fonts/Font18"
import Font24 from "../../../components/Fonts/Font24"
import Font16 from "../../../components/Fonts/Font16"
import { StyledTitle } from "./ForWhoAreOurCourses.styled"
import SchoolIcon from "@mui/icons-material/School"
import WheelchairPickupIcon from "@mui/icons-material/WheelchairPickup"
import Diversity3Icon from "@mui/icons-material/Diversity3"
import { SectionsWrapper, Wrapper } from "../WhyToBuy/WhyToBuy.styled"

const ICON_SIZE = 90

const sections = [
  {
    title: "KANDYDACI NA OPIEKUNÓW OSÓB STARSZYCH",
    description: (
      <Font16 sx={{ textAlign: "center", lineHeight: "25px" }}>
        Nasze kursy są idealnym źródłem wiedzy dla wszystkich osób pragnących
        rozpocząć swoją karierę w branży opieki nad osobami starszymi. Wiedza
        zdobyta podczas uczestnictwa w kursie pozwoli doskonale przygotować się
        do pierwszych dni pracy w tym zawodzie. Ponadto otrzymany certyfikat
        uczyni Cię już na początku Twojej drogi zawodowej{" "}
        <b>ROZCHWYTYWANĄ OPIEKUNKĄ!</b>
      </Font16>
    ),
    icon: (
      <SchoolIcon
        sx={{ width: ICON_SIZE, height: ICON_SIZE, color: "#544485" }}
      />
    ),
  },
  {
    title: "DOŚWIADCZENI OPIEKUNOWIE I PERSONEL MEDYCZNY",
    description: (
      <Font16 sx={{ textAlign: "center", lineHeight: "25px" }}>
        Materiały edukacyjne nagrane w naszych kursach to doskonała forma
        edukacji i odświeżania wiedzy dla doświadczonych opiekunów osób
        starszych lub personelu medycznego pracującego z osobami starszymi.
        Dzięki wiedzy zawartej w naszych materiałach mogą zapoznać się z
        najnowszymi wytycznymi i zaktualizować swoją dotychczasową wiedzę.
      </Font16>
    ),
    icon: (
      <WheelchairPickupIcon
        sx={{ width: ICON_SIZE, height: ICON_SIZE, color: "#544485" }}
      />
    ),
  },
  {
    title: "RODZINY OSÓB WYMAGAJĄCYCH OPIEKI",
    description: (
      <Font16 sx={{ textAlign: "center", lineHeight: "25px" }}>
        Kursy dostępne na naszej stronie to zbiór najważniejszych informacji na
        temat opieki nad osobami starszymi zmagającymi się z typowymi chorobami
        wieku starczego. Są one doskonałym uzupełnieniem wiedzy dla rodzin i
        bliskich osób starszych, niepełnosprawnych i potrzebujących wsparcia
        opiekunów.
      </Font16>
    ),
    icon: (
      <Diversity3Icon
        sx={{ width: ICON_SIZE, height: ICON_SIZE, color: "#544485" }}
      />
    ),
  },
]

const ForWhoAreOurCourses = () => {
  return (
    <section
      style={{
        backgroundColor: "#EBF3F4",
        margin: "0 -24px",
        padding: "60px 24px",
      }}
    >
      <StyledTitle tt="uppercase">DLA KOGO SĄ NASZE KURSY?</StyledTitle>
      <Font18
        fw={700}
        sx={{ textAlign: "center", maxWidth: "80%", margin: "40px auto 0" }}
      >
        Chcesz więcej zarabiać? Masz dość niskich stawek i ciągłych pytań o
        Twoje dyplomy, certyfikaty i szkolenia? Chcesz w końcu móc zarabiać tyle
        ile marzysz, wybierać wśród najlepszych zleceń i pracować w miejscu
        zgodnym z Twoimi oczekiwaniami? Ten kurs jest dla Ciebie! Certyfikat
        otrzymany wraz z kursem uczyni Cię opiekunką o którą bić będzie się
        każda agencja!
      </Font18>
      <Font18
        fw={700}
        sx={{ textAlign: "center", maxWidth: "80%", margin: "40px auto 0" }}
      >
        Ponadto, nasze kursy dzięki swojej przejrzystej formie są idealnym
        rozwiązaniem dla doświadczonych opiekunów osób starszych, kandydatów na
        stanowisko opiekuna oraz rodzin osób chorych.
      </Font18>
      <SectionsWrapper>
        {sections.map(({ icon, title, description }) => (
          <Wrapper>
            {icon}
            <Font24
              color="secondary"
              sx={{ margin: "20px 0", textAlign: "center" }}
            >
              {title}
            </Font24>
            {description}
          </Wrapper>
        ))}
      </SectionsWrapper>
    </section>
  )
}

export default ForWhoAreOurCourses
